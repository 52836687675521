<template>
    <v-container class="mt-7 mb-10 px-0">
        <h1 class=" text-center"> Kontaktieren Sie uns</h1>  
      <v-row no-gutters>

        <v-col cols="0" md="1">
        </v-col>

          <v-col
            cols="12"
            md="4"
            >
          <v-card class="py-10 mt-10 rounded-0 text-center" elevation="0" color="#cbb19b">
            <p class="white--text">
              <b>Adresse</b>
              <br/>
              Frenken Architekten
              <br/>
              Adolf-Silverberg-Str. 19
              <br/>
              50181 Bedburg
              <br/>
            </p>
            <div class="mt-5 mb-5">
              <v-btn
                color="white"
                outlined
                class="text-center rounded-0"
                target="_blank"
                @click="setGoogleMapsRequestToTrue()"
              >
                <v-icon left>
                  mdi-navigation
                </v-icon>
                Anfahrt
              </v-btn>
            </div>
            <p class="white--text">
              <b>Telefon</b>
              <br/>
              02272 938167
              <br/>
              02272 938193
            </p>
            <div class="mt-5 mb-5">
              <v-btn
                color="white"
                outlined
                class="text-center rounded-0"
                href="tel:+492272938167"
              >
                <v-icon left>
                  mdi-phone
                </v-icon>
                Anruf
              </v-btn>
            </div>
            <p class="white--text">
              <b>Email</b>
              <br/>
              info[at]frenken-architekten.de
            </p>
            <div class="mt-5 mb-5">
              <v-btn
                color="white"
                outlined
                class="text-center rounded-0"
                href="mailto:info@frenken-architekten.de"
              >
                <v-icon left>
                  mdi-email
                </v-icon>
                Email
              </v-btn>
            </div>
            </v-card>
        </v-col>

        <v-col cols="2" md="1">
        </v-col>
      
        <v-col cols="8" md="5">  
            <!--<h2 class="text-center mb-10">Kontaktformular</h2>-->
            <v-form
            ref="form"
            lazy-validation
            class="mt-10"
            >
            <v-text-field
              v-model="name"
              :rules="nameRules"
              label="Name*"
              color="#cbb19b"
              outlined
              class="rounded-0"
              required
              dense
            ></v-text-field>

            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="Email*"
              color="#cbb19b"
              outlined
              class="rounded-0"
              required
              dense
            ></v-text-field>

            <v-text-field
              v-model="phoneNumber"
              label="Telefon"
              color="#cbb19b"
              outlined
              class="rounded-0"
              dense
            ></v-text-field>

            <v-text-field
              v-model="subject"
              :rules="subjectRules"
              label="Betreff*"
              color="#cbb19b"
              outlined
              class="rounded-0"
              dense
            ></v-text-field>

            <v-textarea
              v-model="text"
              :rules="textRules"
              label="Ihre Nachricht*"
              color="#cbb19b"
              outlined
              required
              class="rounded-0"
            >
            </v-textarea>

            <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'Sie müssen zustimmen, um das Formular absenden zu können.']"
              label="Ich bin mit der Speicherung meiner Daten gemäß Datenschutzerklärung einverstanden."
              color="#cbb19b"    
              class="ma-0 pa-0"
              required
            ></v-checkbox>

            <div class="text-center mt-5">
              <v-btn
                :disabled="!valid"
                color="grey darken-3"
                outlined
                class="mr-4 justify-center align-center text-center rounded-0"
                @click="validate"
              >
                Senden
              </v-btn>
            </div>
          </v-form>

          <v-overlay v-if="showProgressIcon">
            <v-progress-circular
              :size="50"
              :width="5"
              color="#DEB887"
              indeterminate
            ></v-progress-circular>
          </v-overlay>

          <ContactFormStatus v-if="isSubmitted" v-bind:contactRequestStatus="[contactRequestStatus, text]"/>
          <GoogleMapsConsent v-if="isGoogleMapsRequested"/>

        </v-col>

        <v-col cols="2" md="1">
        </v-col>

      </v-row>

    </v-container>
    
</template>

<script>
  import ContactFormStatus from '@/components/ContactFormStatus.vue';
  import GoogleMapsConsent from '@/components/GoogleMapsConsent.vue';

  export default {
    name: 'Contact',
    components: {
      ContactFormStatus,
      GoogleMapsConsent
    },

    metaInfo: {
      title: 'Kontakt | Frenken Architekten | Ihre Architekten in Bedburg',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Frenken Architekten - Ihre Architekten für Bedburg und Umgebung | Kontaktieren sie uns gerne.'}
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de/kontakt'}
      ]
    },

    data() {
      return {
        valid: true,
        name: '',
        nameRules: [
          v => !!v || 'Name ist erforderlich',
          v => (v && v.length <= 40) || 'Das Limit von 40 Zeichen ist überschritten',
        ],
        email: '',
        emailRules: [
          v => !!v || 'Email Adresse ist erforderlich',
          v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine gültige Email Adresse ein.',
        ],
        subject: '',
        subjectRules: [
          v => !!v || 'Bitte geben Sie einen Betreff ein.',
          v => (v && v.length <= 40) || 'Bitte geben Sie einen Betreff mit weniger als 40 Zeichen ein.',
        ],
        text: '',
        textRules: [
          v => !!v || 'Bitte geben Sie eine Nachricht ein.',
          v => (v && v.length <= 600) || 'Bitte geben Sie einen Betreff mit weniger als 600 Zeichen ein.',
        ],
        phoneNumber: '',
        checkbox: false,
        isSubmitted: false,
        isGoogleMapsRequested: false,
        contactRequestStatus: '',
        showProgressIcon: false
      }
    },

        methods: {
          setGoogleMapsRequestToTrue () {
            this.isGoogleMapsRequested = true;
          },
          setGoogleMapsRequestToFalse () {
            this.isGoogleMapsRequested = false;
          },
          validate () {
            if (this.$refs.form.validate()) {
              this.sendContactRequest()
            }
          },
          sendContactRequest () {
            this.showProgressIcon = true;

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ 
                name: this.name,
                email: this.email,
                phoneNumber: this.phoneNumber,
                subject: this.subject,
                text: this.text})
            };
            fetch('https://email-service.frenken-architekten.de/send', requestOptions)
              .then(async response => {
                const data = await response;

                if (response.ok) {
                  this.contactRequestStatus = 'success';
                  this.isSubmitted = true;
                  this.showProgressIcon = false;
                } else {
                  const error = (data && data.message) || response.status;
                  return Promise.reject(error);
                }
              })
              .catch(error => {
                console.error('There was an error!', error);
                this.showProgressIcon = false;
                this.contactRequestStatus = 'fail';
                this.isSubmitted = true;
              });
          },
          clearContactForm() {
            this.$refs.form.reset();
            this.isSubmitted = false;
            this.contactRequestStatus = '';
          }
    },
  }
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: thin solid #CCCCCC;
}

</style>


