<template class="ml-0">


    <v-container fluid class="pa-0">
<!--
        <div class="ml-0">
            <v-img :src="this.headerImage">
            </v-img>
        </div>
-->
      <v-row no-gutters align="center"
      justify="center">

        <v-col
            cols="1"
            md="2"
            >
        </v-col>
      
        <v-col cols="10" md="8">
            <br/>
            <br/>

            <h1 class=" text-center"> Leistungsphasen eines Architekten - einfach erklärt
            </h1>

            <br/>

            <h2 class=" text-center"> Welche Leistungen Bauherr_innen von Ihrem Architekturbüro erwarten dürfen</h2>

            <br/>

            <p class="text-justify">Entsprechend der Honorarbrechnung f&uuml;r Architekt_innen und Ingenieur_innen (HOAI) werden die Leistungen eines Architekten in 9 Phasen unterteilt. Dabei muss nicht jedes Architekturb&uuml;ro alle Leistungen erbringen. Welche Leistungen sie als Bauherr_innen in Anspruch nehmen m&ouml;chten, sollten sie sich daher vorher gut &uuml;berlegen und daraufhin in Erfahrung bringen, ob ihr Wunsch-Architekturb&uuml;ro diese Leistungen auch anbietet.</p>
            
            <h3 class="text-center">Wieso Leistungsphasen?</h3>

            <br/>

            <p class="text-justify">Die Leistungsphasen dienen nicht nur dem Architekturb&uuml;ro f&uuml;r seine Honorarbrechnung nach der HOAI, sondern auch den Bauherr_innen bei der Planung ihres zuk&uuml;nftigen Eigenheims. Sie geben Sicherheit und Orientierung im Planungsprozess, da sie sich am Bauablauf orientieren. Zudem erm&ouml;glichen sie euch als Kunden eine fundierte finanzielle Planung.</p>

            <h3 class="text-center">Die 9 Leistungsphasen eines Architekturbüros</h3>

            <br/>

            <h4 class="text-center">Leistungsphase 1: Grundlagenermittlung</h4>

            <br/>

            <p class="text-justify">In der ersten Phase werden Bedingungen gekl&auml;rt, die ein erfolgreicher Bauabschluss zwingend voraussetzt. Das Grundst&uuml;ck wird besichtigt und das weitere Vorgehen wird gemeinsam erarbeitet. Sofern noch keine genauen Planungsziele vorliegen, findet vorab eine Leistungsphase 0, die Zielfindungsphase, statt.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 2: Vorplanung</h4>

            <br/>

            <p class="text-justify">Im Anschluss an die Grundlagenermittlung beginnt die Phase der Vorplanung. Es findet eine Grundlagenanalyse statt, die Aufgabenstellung wird abgestimmt und das Planungskonzept wird erarbeitet. Darauf basierend werden die Kosten des Bauvorhabens erstmals abgesch&auml;tzt.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 3: Entwurfsplanung</h4>

            <br/>

            <p class="text-justify">In der Phase der Entwurfsplanung wird ihr Traum vom Haus auf Papier gebracht und sie k&ouml;nnen den Entwurf zum ersten Mal in ihren H&auml;nden halten. Ihre Vorstellungen und W&uuml;nsche werden zu gedruckten Linien auf Baupl&auml;nen, die sp&auml;ter die zust&auml;ndigen Beh&ouml;rden und Dienstleister erhalten.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 4: Genehmigungsplanung</h4>

            <br/>

            <p class="text-justify">In der Genehmigungsplanung werden alle notwendigen Antr&auml;ge vorbereitet und bei den zust&auml;ndigen Beh&ouml;rden eingereicht. Das beinhaltet Antr&auml;ge auf Genehmigung, Ausnahmen und Befreiung. Im Fokus steht selbstverst&auml;ndlich der Bauantrag, der bei der Bauaufsichtsbeh&ouml;rde gepr&uuml;ft wird. Wird der Bauantrag genehmigt, kann es los gehen!</p>

            <br/>

            <h4 class="text-center">Leistungsphase 5: Ausführungsplanung</h4>

            <br/>

            <p class="text-justify">Nun werden Ausf&uuml;hrungspl&auml;ne erstellt, die in gr&ouml;&szlig;erem Ma&szlig;stab die n&ouml;tigen Details f&uuml;r die Umsetzung des Bauvorhabens visualisieren. Somit wird der Entwurf aus der Genehmigungsplanung in der f&uuml;nften Phase verfeinert.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 6: Vorbereitung der Vergabe</h4>

            <br/>

            <p class="text-justify">Leistungsbeschreibungen und Leistungsverzeichnisse dienen in der 6. Leistungsphase eines Architekturb&uuml;ros der Vergabe und der Kostenermittlung des Bauvorhabens.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 7: Mitwirkung bei der Vergabe</h4>

            <br/>

            <p class="text-justify">In der 7. Leistungsphase werden Auftr&auml;ge vergeben und koordiniert. Dies geschieht basierend auf den Angeboten, die vom Architekturb&uuml;ro und den Bauherr innen eingeholt werden.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 8: Bauüberwachung</h4>

            <br/>

            <p class="text-justify">Nun beginnt der Hausbau und der Entwurf, der bisher nur auf Papier existierte, wird langsam aber sicher Realit&auml;t. Damit alles den W&uuml;nschen der Bauherr_innen entspricht und keine Missverst&auml;ndnisse auf der Baustelle aufkommen, sollte eine Bau&uuml;berwachung stattfinden. Diese ist in der Regel sehr zeitintensiv und kann zum Beispiel von einem Architekt/einer Architektin übernommen werden. Die Entscheidung, ob ihr zu diesem Zeitpunkt die Verantwortung in gute und erfahrene Hände legen wollt, müssen sie allerdings alleine treffen.</p>

            <br/>

            <h4 class="text-center">Leistungsphase 9: Objektbetreuung und Dokumentation</h4>

            <br/>

            <p class="text-justify">Nach dem Abschluss der Bauarbeiten wird in der letzten Phase eine Begehung durchgef&uuml;hrt, um eventuelle M&auml;ngel aufzunehmen. Dabei sollten insbesondere Gew&auml;hrleistungsfristen beachtet werden und die Beseitigung der M&auml;ngel sichergestellt werden.</p>

            <br/>

            <h3 class="text-center">Fazit</h3>

            <br/>

            <p>Das Leistungsspektrum eines Architekturb&uuml;ros ist vielf&auml;ltig, doch unterscheidet es sich von Fall zu Fall. <b>Frenken Architekten</b> &uuml;bernimmt als Ihr Architekturb&uuml;ro in Bedburg die Leistungsphasen 1-8. F&uuml;r Bauvorhaben in Grevenbroich, Bergheim, Kerpen, H&uuml;rth, D&uuml;ren, Pulheim und mehr. F&uuml;r ein Erstgespr&auml;ch, also die Leistungsphase 0 bzw. 1, stehen wir Ihnen jederzeit gerne zur Verf&uuml;gung und freuen uns bereits von Ihren Ideen zu h&ouml;ren und sie gemeinsam umzusetzen. Bei uns ist Ihre Idee in guten H&auml;nden.</p>

            <br/>
            <br/>
            <br/>
        </v-col>
        <v-col class="text-right" cols="1" md="2">
        </v-col>

      </v-row>

    </v-container>

</template>

<script>
  export default {
    name: 'ServicePhases',

    metaInfo: {
      title: 'Die Leistungsphasen eines Architekten',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Wir von Frenken Architekten haben für euch zusammengestellt, welche Leistungen Bauherr_innen von Ihrem Architekturbüro erwarten dürfen.'},
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de/leistungsphasen-eines-architekten'}
      ]
    },

    data() {
      return {
        headerImage: require('@/assets/leistungsphasen-architekt.png')
      }
    },
  }
</script>

<style scoped>

</style>