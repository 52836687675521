
<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
    >

      <v-card class="rounded-0">
        <div>
            <v-card-title class="text-h5 justify-center grey lighten-3">
            Weiterleitung zu Google Maps
            </v-card-title>

            <v-card-text class="text-center mt-5">
            Sie werden auf eine externe Website, Google Maps, weitergeleitet. Sind Sie damit einverstanden?
            </v-card-text>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            href="https://www.google.com/maps/search/?api=1&query=Adolf-Silverberg-Str%2019%2050181%20Bedburg"
            target="_blank"
            @click="setDialogToFalse()"
          >
            Ja
          </v-btn>

          <v-btn
            color="secondary"
            text
            @click="setDialogToFalse()"
          >
            Nein
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    name: 'GoogleMapsConsent',
    data () {
      return {
        dialog: true,
      }
    },
    methods: {
        setDialogToFalse(){
            this.dialog = false;
            this.$parent.setGoogleMapsRequestToFalse();
        }
    }
  }
</script>