<template class="ml-0">
    <v-container fluid class="mt-7">

      <v-row no-gutters align="center"
      justify="center">
      
      <v-col
          cols="1"
          md="2">
      </v-col>

      <v-col cols="10" md="8">

         <v-card
    class="mx-auto ml-4 mb-10" elevation="0"
  >
    <v-card-item class=text-center>
      <div>
        <div class="text-overline mb-1">
          Blog #2
        </div>
        <div class="text-h6 mb-1">
          Gut vorbereitet beim Architekten: Planungstipps für Bauherren
        </div>
        <div class="text-caption">So bereiten Sie sich auf ihren ersten Termin im Architekturbüro vor</div>
      </div>
    </v-card-item>

    <br>

    <v-card-actions class="justify-center align-center">
              <v-btn
                color="grey darken-3"
                outlined
                class="mr-4 justify-center align-center text-center rounded-0"
                to="/planungstipps-fuer-bauherren"
              >
                Lesen
              </v-btn>
    </v-card-actions>
  </v-card>

  <v-divider></v-divider>


         <v-card
    class="mx-auto ml-4 mt-10 mb-10" elevation="0"
  >
    <v-card-item class=text-center>
      <div>
        <div class="text-overline mb-1">
          Blog #1
        </div>
        <div class="text-h6 mb-1">
          Die Leistungsphasen eines Architekten
        </div>
        <div class="text-caption">Welche Leistungen Bauherr_innen von Ihrem Architekturbüro erwarten dürfen</div>
      </div>
    </v-card-item>

    <br>

    <v-card-actions class="justify-center align-center">
              <v-btn
                color="grey darken-3"
                outlined
                class="mr-4 justify-center align-center text-center rounded-0"
                to="/leistungsphasen-eines-architekten"
              >
                Lesen
              </v-btn>
    </v-card-actions>
  </v-card>
      </v-col>

      <v-col class="text-right" cols="1" md="2">
      </v-col>

    </v-row>

    </v-container>

</template>

<script>
  export default {
    name: 'Blog',

    metaInfo: {
      title: 'Der Blog | Frenken Architekten',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Der Blog des familiengeführten Architekturbüros Frenken Architekten'},
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de/blog'}
      ]
    },

    data() {
      return {
        headerImage: require('@/assets/leistungsphasen-architekt.png')
      }
    },
  }
</script>

<style scoped>

</style>