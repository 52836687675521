<template>
    <v-container class="mt-7">
      <v-row no-gutters align="center"
      justify="center">

        <v-col
            cols="1"
            md="2"
            >
        </v-col>
      
        <v-col cols="10" md="8">
            <h1 class=" text-center"> Impressum
            </h1>    
            <v-card class="px-5 py-5 mb-10" elevation="0">
      
              <p class=" grey--text text--darken-1">
                Angaben gemäß § 5 TMG
                <br/>
                <br/>
                <b>Architekturbüro Andrea Frenken</b>
                <br/>
                Frau Andrea Frenken
                <br/>
                Adolf-Silverberg-Str. 19
                <br/>
                50181 Bedburg
                <br/>
                <br/>
                <b>Kontakt</b>
                <br/>
                Telefon: 02272 938167
                <br/>
                Fax: 02272 938193
                <br/>
                E-Mail: info[at]frenken-architekten.de
                <br/>
                <br/>
                <b>Berufsbezeichnung</b>
                <br/>
                Andrea Frenken, Architektin Dipl. Ing. (FH)
                <br/>
                Herrmann Frenken, Architekt Dipl. Ing. (FH)
                <br/>
                Staat, in dem die Titel verliehen wurde: Bundesrepublik Deutschland, Bundesland: Nordrhein-Westfalen
                <br/>
                Zuständige Kammer: Architektenkammer NRW,
                <br/>
                Körperschaft des öffentlichen Rechts
                <br/>
                Zollhof 1
                <br/>
                40221 Düsseldorf
                <br/>
                www.aknw.de
                <br/>
                <br/>
                <b>Berufsrechtliche Regelungen</b>
                <br/>
                Es gelten folgende berufsrechtliche Regelungen, die über die Internetseiten der Architektenkammer NRW (http://www.aknw.de/mitglieder/gesetze-verordnungen/index.htm) zugänglich sind:
                <br/>
                <br/>
                (1) Baukammerngesetz NRW - BauKaG NRW, einsehbar unter https://www.aknw.de/recht/gesetze-und-verordnungen
                <br/>
                (2) Durchführungsverordnung zum Baukammerngesetz - DVO BauKG NRW, einsehbar unter https://www.aknw.de/recht/gesetze-und-verordnungen
                <br/>
                (3) Satzung der Architektenkammer NRW, einsehbar unter https://www.aknw.de/ueber-uns/satzungen/ordnungen
                <br/>
                (4) Honorarordnung für Architekten und Ingenieure (HOAI), einsehbar unter https://www.aknw.de/berufspolitik/special-hoai/hoai-2013
                <br/>
                <br/>
                <b>Berufshaftpflichtversicherung</b>
                <br/>
                AIA AG
                <br/> 
                Kaistraße 13, 40221 Düsseldorf
                <br/>
                Versichertes Risiko: Berufshaftpflichtversicherung für Architekten
                <br/>
                Geltungsraum der Versicherung: Nordrhein-Westfalen
                <br/>
                <br/>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              </p>
            </v-card>

        </v-col>
        <v-col class="text-right" cols="1" md="2">
        </v-col>

      </v-row>

    </v-container>

</template>

<script>
  export default {
    name: 'Imprint',

    metaInfo: {
      title: 'Impressum von frenken-architekten.de',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Impressum von frenken-architekten.de'},
        {name: 'robots', content: 'noindex'}
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de/impressum'}
      ]
    },

    data() {
      return {
        
      }
    },
  }
</script>

<style scoped>

</style>