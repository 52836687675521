<template>
  <div class="text-center">
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-top-transition"
        >
        <v-card>
        <v-toolbar
            color="white"
            class="rounded-0"
            elevation="0"
        >
            <v-spacer></v-spacer>
            <v-btn
            icon
            light
            @click="setDialogToFalse()"
            >
            <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <div class="text-center">
          <v-btn block large text class="mt-5 mb-5 rounded-0" @click="navigateToHome()" active-class="no-active">Home</v-btn>
          <!--<v-btn text class="mx-3 font-weight-bold rounded-0" active-class="no-active"> Projekte</v-btn>  
          <v-btn text class="mx-3 font-weight-bold rounded-0" active-class="no-active"> Büro</v-btn>-->
          <v-spacer/>
          <v-btn block large text class="mb-5 rounded-0" @click="navigateToContact()" active-class="no-active">Kontakt</v-btn>
          <v-spacer/>
          <v-btn block large text class="mb-5 rounded-0" @click="navigateToBlog()" active-class="no-active">Blog</v-btn>
        </div>
      </v-card>

        </v-dialog>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'MobileMenu',
    data () {
      return {
        dialog: true,
      }
    },
    methods: {
        navigateToHome(){
            this.$router.push('/');
            this.setDialogToFalse();
        },
        navigateToContact(){
            this.$router.push('/kontakt');
            this.setDialogToFalse();
        },
        navigateToBlog(){
            this.$router.push('/blog');
            this.setDialogToFalse();
        },
        setDialogToFalse(){
            this.dialog = false;
            this.$parent.$parent.setMobileMenuToFalse();
        }
    }
  }
</script>

<style scoped>

    .v-btn {
        font-size: 1.2em;
    }

    .v-btn--active.no-active::before {
    opacity: 0 !important;
    }

</style>