<template>
  <v-app id="app">

    <MobileMenu v-if="isMobileMenuShown"/>

    <v-app-bar
      app
      color="primary"
      light
      elevation="0"
      hide-on-scroll
      height="150px"
    >

    <v-row no-gutters align="center"
      justify="center">
      <v-col
          cols="6"
          md="2">      
        <router-link to="/">
        <div class="d-flex">
            <v-img
              alt="Frenken Architekten Logo"
              contain
              :class="{'ml-8': $vuetify.breakpoint.mdAndUp}"
              src="./assets/frenken-architekten-draft-logo.png"
              transition="scale-transition"
              max-height="80"
              max-width="200"
            /> 
          </div>
        </router-link>
      </v-col>
      <v-col md="8">        
        <div class="text-center d-none d-md-block">
          <v-btn text class="mx-3 font-weight-bold rounded-0" to="/" active-class="no-active"> Home</v-btn>
          <!--<v-btn text class="mx-3 font-weight-bold rounded-0" active-class="no-active"> Projekte</v-btn>  
          <v-btn text class="mx-3 font-weight-bold rounded-0" active-class="no-active"> Büro</v-btn>-->
          <v-btn text class="mx-3 font-weight-bold rounded-0" to="/kontakt" active-class="no-active"> Kontakt</v-btn>
          <v-btn text class="mx-3 font-weight-bold rounded-0" to="/blog" active-class="no-active"> Blog</v-btn>
        </div>
      </v-col>
      <v-col class="text-right" cols="6" md="2">
      <v-app-bar-nav-icon class="d-md-none" large color="black" @click="showMobileMenu()">
      </v-app-bar-nav-icon>
      </v-col>
    </v-row>
    </v-app-bar>

    <v-main>
        <router-view></router-view>
    </v-main>

    <v-divider class="ml-6 mr-6"></v-divider>

    <v-footer padless>
      <v-card
        tile
        flat
        width="100%"
        color="white"
        text-color="#bcada3"
        elevation="2"
        dark
        height="60px"
        class="d-flex align-center justify-center"
      >
        <div class="black--text text--darken-1 text-caption"> 
          <router-link to="/impressum" style="text-decoration: none; color: inherit;" >Impressum</router-link>
          | 
          <router-link to="/datenschutz" style="text-decoration: none; color: inherit;">Datenschutz</router-link>
          |
          <router-link to="/kontakt" style="text-decoration: none; color: inherit;">Kontakt</router-link>
          </div>
      </v-card>
    </v-footer>

  </v-app>
</template>

<script>

import MobileMenu from '@/components/MobileMenu.vue';

export default {
  name: 'App',

  components: {
    MobileMenu, 
  },

  data: () => ({
    isMobileMenuShown: false,
  }),

  methods: {
    showMobileMenu () {
      this.isMobileMenuShown = true;
    },
    setMobileMenuToFalse () {
      this.isMobileMenuShown = false;
    },
  },
};
</script>

<style lang="scss">
@import "./styles/variables.scss";

v-app {
  font-family: $body-font-family !important;
}

.v-btn {
  background-color: transparent;
}
.btn {
  padding: 0.8rem 1rem 0.7rem;
  border: 0.2rem solid #4d4d4d;
  cursor: pointer;
  color: green;
  text-transform: capitalize;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

</style>


