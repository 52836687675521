<template>

  <div>

    <div class="mx-md-14">
          <v-carousel v-model="model"
            cycle
            hide-delimiters
            :show-arrows="false"
            >
            <v-carousel-item
                v-for="(item,i) in carouselItems"
                :key="i"
                :src="item.src"
              ></v-carousel-item>
           </v-carousel>
    </div>

    <v-container
    class="mt-7">
      <v-row no-gutters align="center"
      justify="center">
      
      <v-col
          cols="1"
          md="2"
          >
      </v-col>
      
      <v-col cols="10" md="8">
          <h1 class=" text-center"> Ihre Architekten in Bedburg
          </h1>    
          <v-card class="px-5 py-5 mb-10" elevation="0">
    
            <p class="text-justify grey--text text--darken-3">
              Aus Ihren Wohnideen und unserem Fachwissen wird Architektur für die Zukunft. Unser familiengeführtes Architekturbüro in Bedburg entwirft seit über einem Jahrzehnt Einfamilienhäuser, Mehrfamilienhäuser, Bürogebäude, Hallen und Gewerbebauten für Bergheim und Umgebung. Wir agieren über die Grenzen des Rhein-Erft-Kreises hinaus, vom Entwurf bis hin zur Bauleitung, und begleiten Sie persönlich auf ihrem Weg zum Traumhaus.
            </p>
          </v-card>

          <v-sheet outlined color="transparent" class="rounded-0 mb-15">
            <v-card
              class="rounded-0"
              color="white"
              outlined
            >
              <v-img
                class="grey--text darken-3 align-start"
                height="300px"
                :src="this.wohnraeumeImageUrl" 
              >
              </v-img>

              <v-card-title class="justify-center pt-5" >Wohnräume</v-card-title>

              <v-card-subtitle class="justify-center align-center text-center py-3 black--text">
                Egal ob Einfamilienhaus, Mehrfamilienhaus oder Doppelhaus, Neubau, Umbau oder Anbau: Frenken Architekten setzt Wohnideen und Wünsche nach höchsten Qualitätsstandards, mit Kreativität und persönlicher Note gemeinsam mit Ihnen um und lässt aus Wohntraum Wirklichkeit werden.
              </v-card-subtitle>

              <v-card-actions class="justify-center align-center">
                <div class="text-center mt-5">
                  <v-btn
                    color="#cbb19b" 
                    outlined
                    class="mr-4 justify-center align-center text-center rounded-0"
                    to="/kontakt"
                  >
                    Sprechen Sie uns an
                  </v-btn> <!--TODO Zu den Projekten -->
                </div>
              </v-card-actions>

            </v-card>
          </v-sheet>

          <v-sheet outlined color="transparent" class="rounded-0 mb-15">
            <v-card
              class="rounded-0"
              color="white"
              outlined
            >
              <v-img
                class="grey--text darken-3 align-start"
                height="300px"
                :src="this.arbeitsweltenImageUrl" 
              > 
              </v-img>

              <v-card-title class="justify-center pt-5">Arbeitswelten</v-card-title>

              <v-card-subtitle class="justify-center align-center text-center py-3 black--text">
                Ihre erfahrenen Architekten für Bergheim, Grevenbroich, Kerpen und Umgebung planen, gestalten und betreuen den Bau von Bürogebäuden, gewerblichen Hallen und Gewerbebauten motiviert und gewissenhaft. Wir sind Ihre Ansprechpartner für gewerbliche Architektur in und um Bedburg.
              </v-card-subtitle>

              <!-- Alternative color for: Zu den Projekten: "#DEB887" -->

              <v-card-actions class="justify-center align-center">
                <div class="text-center mt-5">
                  <v-btn
                    color="#cbb19b"
                    outlined
                    class="mr-4 justify-center align-center text-center rounded-0"
                    to="/kontakt"
                  >
                    Sprechen Sie uns an
                  </v-btn>
                </div>
              </v-card-actions>

            </v-card>
          </v-sheet>

      </v-col>
      <v-col class="text-right" cols="1" md="2">
      </v-col>
    </v-row>

    </v-container>

  </div>

</template>

<script>
  export default {
    name: 'Home',

    metaInfo: {
      title: 'Frenken Architekten | Ihre Architekten in Bedburg',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Frenken Architekten ist Ihr Architekturbüro für den Neubau sowie Umbau von Einfamilienhäusern, Mehrfamilienhäusern, Doppelhäusern, Bürogebäuden, Hallen, Gewerbebauten und Industriebauten.'}
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de'}
      ]
    },

    data() {
      return {
      show: false,
      model: null,
      arbeitsweltenImageUrl: require('@/assets/arbeitswelten.jpg'),
      wohnraeumeImageUrl: require('@/assets/wohnraeume.jpg'),
      carouselItems: [
      {
        src: require('@/assets/frenken-architekten-mehrfamilienhaus.jpg')
      },
      {
        src: require('@/assets/frenken-architekten-wohnhaus.jpg')
      },
      {
        src: require('@/assets/frenken-architekten-mehrfamilienhaus-2.jpg')
      },
      {
        src: require('@/assets/frenken-architekten-mehrfamilienhaus-3.jpg')
      }
     ]
      }
    },

    created() {
      /*
      const headers = { "Content-Type": "application/json",
      "Authorization": "bearer " + process.env.VUE_APP_CMS_AUTH_TOKEN};
      fetch("https://backend.frenken-architekten.de/api/home?populate=*", { headers })
        .then(async response => {
          const data = await response.json();
          if (!response.ok) {
          // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          const content = data.data.attributes;
          this.welcomeTextTitle = content.WelcomeTextTitle;
          this.welcomeText = content.WelcomeText;
          this.wohnraeumeTitle = content.WohnraeumeTitle;
          this.wohnraeumeText = content.WohnraeumeText;
          this.arbeitsweltenTitle = content.ArbeitsweltenTitle;
          this.arbeitsweltenText = content.ArbeitsweltenText;
          this.arbeitsweltenImageUrl = content.ArbeitsweltenImage.data.attributes.formats.medium.url;
          this.wohnraeumeImageUrl = content.WohnraeumeImage.data.attributes.formats.medium.url;
          let sliderImages = [];
          content.SliderImages.data.forEach(function(image) {
            sliderImages.push({src: image.attributes.url});
          });

          this.carouselItems = sliderImages;
        })
        .catch(error => {
          this.errorMessage = error;
          console.error("Error", error);
        });*/
    }
  }
</script>

<style scoped>

</style>
