
<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="400"
    >

      <v-card>
        <div v-if="this.contactRequestStatus[0] === 'success'">
            <v-card-title class="text-h5 green lighten-2">
            Kontaktanfrage erfolgreich
            </v-card-title>

            <v-card-text class="text-center mt-5">
            Ihre Kontaktanfrage wurde erfolgreich versendet.
            </v-card-text>
        </div>

        <div v-if="this.contactRequestStatus[0] === 'fail'">
            <v-card-title class="text-h5 red lighten-2">
            Kontaktanfrage fehlgeschlagen
            </v-card-title>

            <v-card-text class="text-center mt-5">
            Es tut uns leid. Auf Grund eines technischen Fehlers konnte Ihre Kontaktanfrage nicht übermittelt werden.
            Bitte senden Sie stattdessen eine Email an <a href="mailto:info@frenken-architekten.de">info[at]frenken-architekten.de</a>
            </v-card-text>

            <v-card-actions class="justify-center">
              <v-btn class="rounded-0" color="grey darken-3" outlined @click="copyText()">Kopiere Nachricht in Zwischenablage
              </v-btn>
            </v-card-actions>
        </div>

        <v-divider class="mt-5"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="clearContactForm()"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  export default {
    name: 'ContactFormStatus',
    props: ['contactRequestStatus'],
    data () {
      return {
        dialog: true,
      }
    },
    methods: {
        clearContactForm(){
            this.dialog = false;
            this.$parent.clearContactForm();
        },
        copyText() {
          navigator.clipboard.writeText(this.contactRequestStatus[1]);
        }
    },
  }
</script>