<template class="ml-0">


    <v-container fluid class="pa-0">

      <v-row no-gutters align="center"
      justify="center">

        <v-col
            cols="1"
            md="2"
            >
        </v-col>
      
        <v-col cols="10" md="8">
            <br/>
            <br/>

            <h1 class=" text-center"> Gut vorbereitet beim Architekten: Planungstipps für Bauherren
            </h1>

            <br/>

            <h2 class=" text-center"> So bereiten Sie sich auf ihren ersten Termin im Architekturbüro vor</h2>

            <br/>

            <p>Bei dem ersten Gespräch mit dem Architekten gibt es natürlich sehr viel zu besprechen. Damit ihr nichts vergesst und gut vorbereitet seid, haben wir hier ein paar Tipps für euch.</p>
            <p>Euer Erstgespräch im Architekturbüro hat mehrere Funktionen: Zum einen findet ihr heraus, ob die Chemie stimmt und ihr gerne mit dem/der Architekt_in zusammenarbeiten möchtet. Zum anderen sollten beidseitig Ziele des Bauvorhabens definiert werden. Seid ihr zufrieden und das Erstgespräch verläuft erfolgreich, steht dem Planungsprozess nichts mehr im Weg und ihr kommt eurem Traum vom Eigenheim einen großen Schritt näher.</p>
            <p>Wenn möglich bringt am besten die folgenden Dokumente mit zu eurem Architekturbüro – oder ihr schickt sie eurem Architekten vorab in einer Mail: den Grundbuchauszug, den Lageplan und Bebauuungsplan sowie die Finanzierungszusage eurer Bank. Möglicherweise ergeben sich hieraus bereits einige Vorgaben, an die ihr euch im Planungs- und Bauprozess halten müsst. </p>
            <p>Bei dem Erstgespräch geht es einem guten Architekturbüro jedoch nicht nur um Formalia. Viel wichtiger sind eure eigenen Vorstellungen und Wünsche. Damit ihr gut vorbereitet erscheint, macht ihr euch am besten im Voraus Gedanken darüber, was euch gefällt und was nicht. Social Media-Plattformen wie Pinterest und Instagram, aber auch eure Lieblingsnachbarschaft, Musterhäuser oder das Portfolio des Architekturbüros eurer Wahl sind hervorragende Inspirationsquellen für das eigene Mood-Board. Bringt daher gerne Fotos und Skizzen zum Erstgespräch mit, um eure Vorstellungen zu veranschaulichen. </p>
            <p>Vielleicht wagt ihr auch bereits einen Blick in die Zukunft: Wie könnte eure Wohnsituation in ein paar Jahren aussehen? Braucht ihr ein Kinderzimmer oder vielleicht sogar zwei? Wünscht ihr euch im Alter ein Schlafzimmer im Erdgeschoss? </p>
            <p>Auch über folgende Eckdaten könnt ihr euch im Vorhinein Gedanken machen:</p>
            <ul>
              <li>Größe des Bauvorhabens (Quadratmeterzahl)</li>
              <li>Wie groß ist eurer Budget?</li>
              <li>Welche Leistungen wollt ihr selbst übernehmen?</li>
              <li>Ausrichtung auf dem Grundstück (Himmelsrichtung)</li>
              <li>Gründung (Ist vielleicht ein Keller gewünscht?)</li>
              <li>Grundriss (Wie sollen die Räume aufgeteilt und angeordnet werden?)</li>
              <li>Energieversorgung/Energieeffizienz (Solarpanele, Fußbodenheizung)</li>
              <li>Haustechnik</li>
              <li>Benötigt ihr eine Garage oder einen Carport? Und wie groß soll diese/r sein?</li>
              <li>Welche Räume braucht ihr? (Kinderzimmer, Arbeitszimmer, Gästezimmer, Sauna, Speisekammer, Waschküche)</li>
              <li>Außenanlagen: Möchtet ihr eine Terrasse oder einen Balkon?</li>
            </ul>
            <br>
            <p>Besonders wichtig sind außerdem eure Vorstellungen bezüglich des Zeitrahmens: Wann sind Baubeginn und Bauende und – besonders wichtig – wann möchtet ihr einziehen? Euer/eure Architekt_in wird euch beraten, ob die Timeline realistisch gesteckt ist und sie gegebenenfalls korrigieren.</p>
<p>Viele Antworten ergeben sich aber auch erst im Laufe der Zeit oder während des Gesprächs mit dem/der Architekt_in. Deswegen solltet ihr euch bei eurem Architekturbüro gut beraten fühlen.</p>
<p>Erstellt für euer Erstgespräch gerne eine Checkliste und schreibt euch eure Ideen und Fragen auf. So vergesst ihr im Termin selbst nichts und es entstehen keine Missverständnisse. Fragen sind nicht nur erlaubt, sondern auch erwünscht.</p>
<p>Das erste Gespräch mit eurem/eurer Architekten/Architektin soll euch zuallererst Planungssicherheit bieten. Jeder weitere Schritt wird sich an dem Besprochenen orientieren – auch, wenn sich natürlich noch alles ändern kann und nichts in Stein gemeißelt ist. Basierend auf euren Angaben im Erstgespräch erstellt euch das Architekturbüro ein Angebot: <b>Und los geht’s!</b></p>
        </v-col>
        <v-col class="text-right" cols="1" md="2">
        </v-col>

      </v-row>

    </v-container>

</template>

<script>
  export default {
    name: 'Preparations',

    metaInfo: {
      title: 'Gut vorbereitet beim Architekten: Planungstipps für Bauherren',
      // If a child changes the title to "My Other Page Title", it will become: My Other Page Title ← My Site
      meta: [
        {name: 'description', content: 'Wir von Frenken Architekten haben für euch zusammengestellt, wie sie sich auf ihren ersten Termin im Architekturbüro vorbereiten.'},
      ],
      link: [
        {rel: 'canonical', href: 'https://frenken-architekten.de/planungstipps-fuer-bauherren'}
      ]
    },

    data() {
      return {
        headerImage: require('@/assets/leistungsphasen-architekt.png')
      }
    },
  }
</script>

<style scoped>

</style>