import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/Imprint.vue'
import DataPrivacy from '../views/DataPrivacy.vue'
import Contact from '../views/Contact.vue'
import ServicePhases from '../views/ServicePhases.vue'
import Preparations from '../views/Preparations.vue'
import Blog from '../views/Blog.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/impressum',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/datenschutz',
    name: 'DataPrivacy',
    component: DataPrivacy,
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/leistungsphasen-eines-architekten',
    name: 'ServicePhases',
    component: ServicePhases,
  },
  {
    path: '/planungstipps-fuer-bauherren',
    name: 'Preparations',
    component: Preparations,
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'auto' });
}
})

export default router
